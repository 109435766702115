.blnking-animation {
  animation: zoomInOut 1s infinite alternate;
}
@keyframes zoomInOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.2); /* Adjust the scale factor as needed */
  }
}
