body{
    font-family:Bebas Neue;  ;
}
@font-face {
    font-family: 'Bebas Neue'; /* Replace with your desired font name */
    src: url('../../assets/fonts/BebasNeue-Regular.ttf') format('truetype'); /* Correct format and path to your font file */
    font-weight: normal;
    font-style: normal;
  }
  
.back-button{
    position: absolute;
    width: 95px;
    height: 26px;
    top: 171px;
    left: 111px;
}
.first-title{
   position: absolute;
   top: 28vh;
   left: 43%;
   font-family: Bebas Neue;



}
.first-title .first{
    color: #ffffff;
    text-transform: uppercase;
    font-family: Bebas Neue;
    font-size: 60px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;

}
.first-title .second{
    text-transform: uppercase;
    font-family: Bebas Neue;
    font-size: 66px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;
    color:  #94E161;

}

.section-one .description{
    position: absolute;
    display: flex;
    justify-items: center;
    align-items: center;
    color: #ffffff;
    margin: 2vh 27vw;
    text-align: center;
    top: 35vh;

}
.section-one .section-one-img{
position: absolute;
top:46%;
left: 10vw;
display: flex;
justify-content: center;
align-items: center;
width: auto;

}


/*section third */
.section-two{
    position: relative;
    margin-top: 66%;
}
.second-title{
    font-family: Bebas Neue;
    padding-top: 14vh;
    position: absolute;
    text-align: left;
    left: 15%;
 }
.section-two .description{
    position: absolute;
    display: flex;
    justify-items: center;
    align-items: center;
    color: #ffffff;
    width: 40vw;
    left:15vw;
    right: 15vw;
    text-align: left;
    padding-top: 21vh;
}
.section-two .section-two-img{
    display: flex;
    justify-content: center;
    align-items: center;
    width: auto;

}

 .second-title .first{
     color: #ffffff;
     text-transform: uppercase;
     font-family: Bebas Neue;
     font-size: 66px;
     font-weight: 700;
     line-height: 66px;
     letter-spacing: 0em;
 
 }
 .second-title .second{
     text-transform: uppercase;
     font-family: Bebas Neue;
     font-size: 66px;
     font-weight: 700;
     line-height: 66px;
     letter-spacing: 0em;
     color:  #94E161;
 
 }





 .third-title{
    position: absolute;
    text-align: right;
    width: 28vw;
    right: 23%;
    text-align: left;
    padding-top: 8vh;
    font-family: Bebas Neue;

 }
 .third-title .first{
    color: #ffffff;
    text-transform: uppercase;
    font-family: Bebas Neue;
    font-size: 66px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;

}
.third-title .second{
    text-transform: uppercase;
    font-family: Bebas Neue;
    font-size: 66px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;
    color:  #94E161;

}

.section-third{
    position: relative;
    margin-top: 5%;
    margin-bottom: 7%;
}
.section-third .description{
    position: absolute;
    display: flex;
    justify-items: center;
    align-items: center;
    color: #ffffff;
    text-align: center;
    padding-top:24vh;
    width: 35vw;
    text-align: left;
    right: 15vw;

}
.section-third .section-third-img{
    display: flex;
    justify-content: center;
    align-items: center;


}

@media (max-width: 768px){

    .back-button{
        top: 30px;
        left: 15px;
    }
  }