/* customScrollBars.css */

/* Style the scroll bar track */
.boxWithCustomScrollBars::-webkit-scrollbar {
  width: 6px; /* Set the width of the scroll bar */
}

/* Style the scroll bar thumb (the draggable part) */
.boxWithCustomScrollBars::-webkit-scrollbar-thumb {
  background-color: #94e161; /* Set the color of the scroll thumb */
  border-radius: 4px; /* Set the border radius of the thumb */
}
