.slick-slider {
  padding-block: 10px;
  .slick-slide {
    padding: 0 15px;

    /* padding: 1vh 5px; */
    /* margin:2vh 2vw; */
  }
 
}
