@font-face {
    font-family: 'Bebas Neue'; /* Replace with your desired font name */
    src: url('../../assets/fonts/BebasNeue-Regular.ttf') format('ttf'); /* Replace with the correct path to your font file */
    font-weight: normal;
    font-style: normal;
  }
.back-button{
    position: absolute;
    width: 95px;
    height: 26px;
    top: 171px;
    left: 111px;
}
.faq-title{
    position: absolute;
    color: #ffffff;
    width: 76px;
    height: 46px;
    top: 233px;
    left: 132px;  
}
.faq-title h3{
    font-family: Bebas Neue;
    font-size: 50px;
    font-weight: 700;
    line-height: 66px;
    letter-spacing: 0em;
    text-align: center;
}
.mutant-collapse{
   position: relative;
   margin-top: 14%;
   display:flex;
   flex-direction: column;
   align-items: center;
   justify-items: center;

}
.container{
    display:flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
    text-align: center;  
    position: absolute;
    left: 20%;
    right: 20%;
 } 
.mutant-collapse h3{
    color:#ffffff;
    font-family: Bebas Neue;
    font-size: 33px;
    font-weight: 700;
    line-height: 80px;
    letter-spacing: 0em;
    text-align: center;
    padding-bottom: 16px;
    margin-top: 22px;

}
@media (max-width: 768px){
    .back-button{
      
        top: 30px;
        left: 15px;
    }
    .faq-title{
        top: 60px;
        left: 15px;
    }
    .faq-title h3{
        font-size: 22px;
        font-weight: 800;
        text-align: center;
    }
    .mutant-collapse h3{
        font-size: 22px;
        font-weight: 400;
        line-height: 80px;
        padding-bottom: 0;
        margin-top: 44px;
    }
    .container{
        display:flex;
        flex-direction: column;
        align-items: center;
        justify-items: center;
        text-align: center;  
        position: absolute;
        left: 1vw;
        right: 1vw;
     } 
  }